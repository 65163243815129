import request from "@/utils/request";
// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}

// 登录
export function login(username, password) {
  const data = {
    username,
    password,
  };
  return request({
    url: "/login",
    method: "post",
    data: data,
  });
}

// 查看报告表单
export function search_report() {
    return request({
      url: "/search_report",
      method: "post",
    });
  }

  // 查看报告表单
export function modify_report(data) {
    return request({
      url: "/modify_report",
      method: "post",
      data:data
    });
  }
  // 查看报告表单
export function avoid_login(data) {
    return request({
      url: "/avoid_login",
      method: "post",
      data:data
    });
  }
  // 上传分数
  export function upload_score(data) {
    return request({
      url: "/upload_score",
      method: "post",
      data:data
    });
  }
  // 上传分数
  export function api_visit(data) {
    return request({
      url: "/api_visit",
      method: "post",
      data:data
    });
  }
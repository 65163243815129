import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

Vue.use(Router);

export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect',
                component: (resolve) =>
                    require(['@/views/redirect.vue'], resolve)
            }
        ]
    },
    // {
    //   path: "",
    //   component: Layout,
    //   redirect: "index",
    //   children: [
    //     {
    //       path: "/index",
    //       component: (resolve) => require(["@/views/gateway.vue"], resolve),
    //       name: "首页",
    //       meta: {
    //         title: "首页",
    //         icon: "dashboard",
    //         // noCache: true,
    //         affix: true,
    //       },
    //     },
    //   ],
    // },
    {
        path: '/',
        component: (resolve) => require(['@/views/gateway.vue'], resolve),
        hidden: true
    },
    // {
    //   path: "/Login",
    //   component: (resolve) => require(["@/views/login.vue"], resolve),
    //   hidden: true,
    // },
    // {
    //   path: "/Resigter",
    //   component: (resolve) => require(["@/views/Resigter.vue"], resolve),
    //   hidden: true,
    // },
    {
        path: '/gateway',
        component: (resolve) => require(['@/views/gateway.vue'], resolve),
        meta: {
            title: '实验入口',
            icon: 'dashboard',
            noCache: true,
            affix: true
        },
        hidden: true
    },
    {
        path: '/experiment',
        component: (resolve) => require(['@/views/experiment.vue'], resolve),
        meta: {
            title: '实验中心',
            icon: 'dashboard',
            noCache: true,
            affix: true
        },
        hidden: true
    },
    {
        path: '/DataQuery',
        component: (resolve) => require(['@/views/DataQuery.vue'], resolve),
        meta: {
            title: '数据查询',
            icon: 'dashboard',
            noCache: true,
            affix: true
        },
        hidden: true
    },
    // 项目介绍
    {
        path: '/ProjectIntroduction',
        component: (resolve) =>
            require(['@/views/ProjectIntroduction.vue'], resolve),
        hidden: true
    },
    {
        path: '/404',
        component: (resolve) => require(['@/views/error/404.vue'], resolve),
        hidden: true
    },
    {
        path: '/401',
        component: (resolve) => require(['@/views/error/401.vue'], resolve),
        hidden: true
    }
]

export default new Router({
  // mode: 'history', // 去掉url中的#
  // mode:'history',
  routes: constantRoutes,
});

<template>
    <el-container class="layout">
        <el-header class="header">
            <div class="title">绿色债券信用评级虚拟仿真实验室</div>
            <div class="describe">
                <div class="help">
                    <i class="el-icon-s-el-icon-s-data"></i>
                    常见问题
                </div>
                <div class="avatar">
                    <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                </div>
                <div class="user-nickname">
                    用户昵称
                </div>
            </div>
        </el-header>
        <el-main class="main">
            <AppMain />
        </el-main>
    </el-container>
</template>

<script>
import { AppMain } from "../components";
import { MessageBox } from 'element-ui'
import { removeToken } from '../utils/auth.js'

export default {
    name: "Layout",
    components: {
        AppMain,
    },
    data(){
        return{

        }
    },
    created() {
    },
    mounted() {
    },
    watch:{
    },
    methods:{
        // 退出登录
        LogOut(){
            MessageBox.confirm('您确定退出登录？', '系统提示', {
            confirmButtonText: '退出登录',
            cancelButtonText: '取消',
            type: 'warning'
        }
            ).then(() => {
                removeToken('Admin-Token')
                location.href = '/';
            })
        },
    }
};
</script>

<style lang="scss" >
.layout{
    width: 100vw;
    height: 100vh;
    .header{
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        background-color: var(--theme-background-color);
        padding: 0;
        .title{
            box-sizing: border-box;
            padding-left: 40px;
            float: left;
            font-size: var(--theme-h2-font-size);
            line-height: 60px;
            font-weight: 600;
            color: var(--theme-title-contrast-color);
        }
        .describe{
            height: 100%;
            float: right;
            color: var(--theme-title-contrast-color);
            font-size: var(--theme-txt-font-size);
            box-sizing: border-box;
            display: flex;
            line-height: 60px;
            .help{
                padding-left: 20px;
            }
            .avatar{
                box-sizing: border-box;
                padding-left: 20px;
                .el-avatar--small{
                    margin-top: 16px;
                }
            }
            .user-nickname{
               padding-left: 5px; 
               padding-right: 20px;
            }
        }
    }
    .main{
        width: 100%;
        height: calc(100% - 60px);
        box-sizing: border-box;
        padding: 0;
    }
}
</style>
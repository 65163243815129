import { login, logout ,search_report} from "@/api/index";
import { getToken, setToken, removeToken } from "@/utils/auth";

const user = {
  state: {
    token: getToken(),
    name: "",
    roles: [],
    permissions: [],
    IsInside: true,
    NoReadingNumber: 0,
    ReportData:'',
  },

  mutations: {
    initWebSockets(state, data) {
      state.NoReadingNumber = data;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    IsInsideState: (state, permissions) => {
      state.IsInside = permissions;
    },
    SET_ReportData: (state, ReportData) => {
      state.ReportData = ReportData;

    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((res) => {
            setToken(res.access_token);
            search_report().then((res) => {
              commit("SET_ReportData", res);
            });
            commit("SET_TOKEN", res.access_token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    IsInsideFunction({ commit }, State) {
      commit("IsInsideState", State);
    },
    // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo(state.token)
    //       .then((res) => {
    //         const user = res.user;
    //         // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //         //     commit('SET_ROLES', res.roles)
    //         //     commit('SET_PERMISSIONS', res.permissions)
    //         // } else {
    //         //     commit('SET_ROLES', ['ROLE_DEFAULT'])
    //         // }
    //         commit("SET_NAME", user.userName);
    //         resolve(res);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            commit("SET_PERMISSIONS", []);
            sessionStorage.clear();
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        sessionStorage.clear();
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
